import {
  ClassProvider,
  Inject,
  Injectable,
  InjectionToken,
  Type,
} from '@angular/core';
import { FeatureFlagService } from '../../utils/feature-flag/feature-flag.service';

export type GAHandoffMode = 'carryout' | 'delivery';

type GAEcommerceItem = {
  item_category: string;
  item_id: string;
  item_name: string;
  price: number;
  quantity: number;
};

type GAViewCartEcommerceItem = {
  item_id: string;
  item_name: string;
  item_category: string;
  price: number;
  quantity: number;
  value: number;
};

type GAFees = {
  amount: number;
  description: string;
};

type GAPromotionEcommerceItem = {
  item_id: string;
  item_name: string;
  promotion_id: string;
  promotion_name: string;
  location_id: string;
};

type GACartEcommerceItem = {
  item_category: string;
  item_id: string;
  item_name: string;
  price: number;
  quantity: number;
  product_name: string;
  currency: 'USD';
  order_method: string;
  special_request: string[];
  store_name: string;
  value: number;
};

type GATipEvent = {
  event: 'tip';
  amount: number;
};

type GAPurchaseEvent = {
  event: 'purchase';
  ecommerce: {
    actionField: {
      id: string;
      coupon?: string;
      revenue: number;
      tax: number;
      shipping?: number;
      currencyCode: 'USD';
    };
    coupon?: string;
    currency: 'USD';
    favorite: boolean;
    items: GAEcommerceItem[];
    order_method: GAHandoffMode;
    order_time: 'later' | 'asap';
    order_total: number;
    registered: boolean;
    shipping: number;
    store_name: string;
    tip_amount: number;
    transaction_id: string;
    value: number;
    payment_provider: 'Fiserv';
    discount: number;
    tax: number;
    fees?: GAFees[];
    fees_total: number;
    payment_type_1: string | null;
    payment_type_2: string | null;
  };
  digital_menu: boolean;
  promo_code?: string;
  reorder: boolean;
  roundup_enabled: boolean;
  roundup_amount: number;
  supports_coke_freestyle: boolean;
};

type GACheckoutEvent = {
  event: 'begin_checkout';
  ecommerce: {
    items: GAEcommerceItem[];
  };
  order_method: string;
  order_time: string;
  registered: boolean;
  store_name: string;
  value: number;
  coupon: string;
  currency: 'USD';
  subtotal: number;
};

type GAAccountOptionClickEvent = {
  event: 'account_click_option';
  account_option_value: string;
};

type GAUserEvent = {
  user_id: string | null | undefined;
};

type GALoginTypeEvent = {
  event: 'login';
  method: string;
};

type GASignupTypeEvent = {
  event: 'sign_up';
  method: string;
};

type GASignupDuringCheckoutTypeEvent = {
  event: 'sign_up';
  method: string;
  source: string;
};

type GAEmailOptInTypeEvent = {
  event: 'opt_in_email';
};

type GAEmailOptOutTypeEvent = {
  event: 'opt_out_email';
};

type GAFlavorSlideTypeEvent = {
  event: 'flavor_slide';
};

type GARoundupEvent = {
  event: 'roundup_tap';
  roundup_action: string;
};

type GAAddToCartEvent = {
  event: 'add_to_cart';
  ecommerce: {
    items: GAEcommerceItem[];
  };
  product_name: string;
};

type GAViewSelectItemEvent = {
  event: 'view_item' | 'select_item';
  ecommerce: {
    items: GAEcommerceItem[];
  };
};

type GAViewListItemEvent = {
  event: 'view_item_list';
  ecommerce: {
    items: GAEcommerceItem[];
  };
};

type GAViewPromotionEvent = {
  event: 'view_promotion';
  ecommerce: {
    items: GAPromotionEcommerceItem[];
  };
};

type GAFeesModalEvent = {
  event: 'service_fees_info_tap';
  info_tap: string;
};

type GASelectItemEvent = {
  event: 'select_item';
  ecommerce: {
    items: [
      {
        item_name: string;
        item_id: string;
        item_list_id: string;
        quantity: 1;
        price: number;
      }
    ];
  };
};

type GADownloadAppEvent = {
  event: 'download_app';
  page: string;
  app_store_type: string;
};

type GARemoveItemFromCartEvent = {
  event: 'remove_from_cart';
  ecommerce: {
    items: GACartEcommerceItem[];
  };
};

type GACViewCartEvent = {
  event: 'view_cart';
  ecommerce: {
    items: GAViewCartEcommerceItem[];
  };
  currency: 'USD';
  value: number;
  order_method: string;
  store_name: string;
};

type GAPromoCodeEvent = {
  event: 'promo_code';
  promo_code: string;
  valid_promo_code: 'valid' | 'invalid';
};

type GALogoutEvent = {
  event: 'logout';
};
type GAUpsellCardEvent = {
  event: 'upsell_view';
  item_category: string;
  item_id: string;
  item_name: string;
  currency: 'USD';
  order_method: string;
  store_name: string;
};

type GAAddOnCardEvent = {
  event: string;
  item: string;
  currency: 'USD';
  value: number;
  parent_item: string;
};

type GACarouselProductEvent = {
  event: 'menu_featured_product';
  product_name: string;
};

type GAOfferApplyErrorEvent = {
  event: 'offer_apply_error';
  coupon: string;
  error_message: string;
};

type GAOfferApplyEvent = {
  event: 'offer_apply';
  coupon: string;
};

type GAChangeStoreEvent = {
  event: 'change_store';
};

type GARoundUpInfoTapEvent = {
  event: 'roundup_info_tap';
};

type GAOrderTimeEvent = {
  event: 'order_time';
};

type GATimeChangeEvent = {
  event: 'time_change';
};

type GAAddMoreItemsEvent = {
  event: 'add_more_items';
};

type GASpecialRequestEvent = {
  event: 'special_request';
};

type GASecondaryAddressModifiedCheckoutRequestEvent = {
  event: 'delivery_secondary_address_modified_checkout';
};

type GAMenuFeaturedCarouselEvent = {
  event: 'menu_featured_carousel';
};

type GAActiveOfferIconTapEvent = {
  event: 'active_offer_icon_tap';
};

type GABagClickEvent = {
  event: 'bag_click';
  screen_name: string;
};

type GAReturnToCartEvent = {
  event: 'return_to_cart';
};

type GAStartNewOrderEvent = {
  event: 'start_new_order';
};

interface GAChooseOptionsEvent {
  event: 'choose_options';
  item_name: string;
  count?: number;
}

type GACrossSellViewEvent = {
  event: 'cross_sell_view';
  item_category: string;
  item_id: string;
  item_name: string;
  currency: 'USD';
  quantity: 1;
  order_method: string;
  store_name: string;
};

type GAViewSearchResultsEvent = {
  event: 'view_search_results';
  distance_of_nearest_store_in_miles: number | null
};

type GADeliveryUnavailableEvent = {
  event: 'delivery_unavailable';
};

type GACarryoutUnavailableEvent = {
  event: 'carryout_unavailable';
};

type GAViewStoreDetailsEvent = {
  event: 'view_store_details';
};

type GACheckoutErrorEvent = {
  event: 'checkout_error';
  error_reason:
  | 'contact_form_validation'
  | 'payment_method_required'
  | 'tip_failed'
  | 'roundup_failed'
  | 'add_gift_card_failed'
  | 'add_credit_card_failed';
};

type GACheckoutPurchaseFailedEvent = {
  event: 'purchase_failed';
  transaction_id: string;
  payment_type_1: string | null;
  payment_type_2: string | null;
};

type GACheckoutPurchaseAttemptEvent = {
  event: 'purchase_attempt';
  purchase_attempt_timestamp: string;
  cart_id: string;
  transaction_id: string;
};

type GACheckoutFiservIFrameErrorEvent = {
  event: 'iframe_error';
  error_response: string;
  payment_provider: 'Fiserv';
};

type GAWingCalculatorCalculateEvent = {
  event: 'wing_calculator_calculate';
  wing_calculator_people_count: number;
  wing_calculator_hunger_index: number;
};

type GAWingCalculatorRunEvent = {
  event: 'run_wing_calculator';
};

type GAWingCalculatorOrderEvent = {
  event: 'wing_calculator_order';
  product_name: string;
};

type GAFavoriteStoreEvent = {
  event: 'favorite_store';
};

type GACallStoreEvent = {
  event: 'call_store';
  page: string;
};

type GAOrderClickEvent = {
  event: 'order_click';
  handoff: string;
  screen_name: string;
};

type GAPaymentSaveTapEvent = {
  event: 'payment_save_tap';
};

type GAProductHomepageClickEvent = {
  event: 'product_homepage_click';
  component: string;
  item_name: string;
};

type GASearchEvent = {
  event: 'search';
  order_method: string;
};

type GAStoresEnableLocationsEvent = {
  event: 'stores_enable_locations';
};

type GAOptInLocationEvent = {
  event: 'opt_in_location';
};

type GAOptOutLocationEvent = {
  event: 'opt_out_location';
};

type GAWingCalculatorViewEvent = {
  event: 'wing_calculator_view';
};

type GARecentOrderAgainEvent = {
  event: 'recent_order_again';
};

type GAReorderEvent = {
  event: 'reorder';
};

type GAChangeHandoff = {
  event: 'change_handoff';
  handoff_type: 'Change to Carryout' | 'Change to Delivery';
};

type GAStoreOrderNow = {
  event: 'store_order_now';
  order_method: string;
};

type GABasketCreated = {
  event: 'basket_created';
  store_number: string;
  order_method: string;
};

type GAProductQuantityTap = {
  event: 'product_quantity_tap';
  item_id: string;
  item_name: string;
  change_type: 'add' | 'minus' | 'manual';
  quantity: number;
};

type GAButtonClickEvent = {
  event: 'button_click';
  event_params: {
    section_title: string;
    button_label: string;
  };
};

type GALoginCheckoutTapEvent = {
  event: 'login_checkout_tap';
};

type GAStoreLocatorMapExplore = {
  event: 'store_locator_map_explore';
};

type GAStoreLocatorMapView = {
  event: 'store_locator_map_view';
};

type GALoyaltyEnrollPrompt = {
  event: 'loyalty_enroll_prompt';
  page: string;
  user_id: string;
};

type GALoyaltyEnrollPromptRespond = {
  event: 'loyalty_enroll_prompt_respond';
  user_id: string;
  enroll_response: string;
};

type GALoyaltyOptIn = {
  event: 'loyalty_opt_in';
  user_id: string;
};

type GAJoinCrewClick = {
  event: 'join_crew_click';
};
type GALoyaltyDashboard = {
  event: 'loyalty_dashboard';
}

export type LogGaEvent =
  | GAAccountOptionClickEvent
  | GAUserEvent
  | GARoundupEvent
  | GALoginTypeEvent
  | GASignupTypeEvent
  | GASignupDuringCheckoutTypeEvent
  | GAEmailOptInTypeEvent
  | GAEmailOptOutTypeEvent
  | GAFlavorSlideTypeEvent
  | GAAddToCartEvent
  | GAPurchaseEvent
  | GAViewSelectItemEvent
  | GACheckoutEvent
  | GATipEvent
  | GAViewListItemEvent
  | GAFeesModalEvent
  | GAViewPromotionEvent
  | GASelectItemEvent
  | GADownloadAppEvent
  | GARemoveItemFromCartEvent
  | GACViewCartEvent
  | GAPromoCodeEvent
  | GAAddOnCardEvent
  | GAUpsellCardEvent
  | GACarouselProductEvent
  | GAOfferApplyErrorEvent
  | GAOfferApplyEvent
  | GAChangeStoreEvent
  | GALogoutEvent
  | GARoundUpInfoTapEvent
  | GAOrderTimeEvent
  | GATimeChangeEvent
  | GAAddMoreItemsEvent
  | GASpecialRequestEvent
  | GASecondaryAddressModifiedCheckoutRequestEvent
  | GAMenuFeaturedCarouselEvent
  | GAActiveOfferIconTapEvent
  | GABagClickEvent
  | GAReturnToCartEvent
  | GAStartNewOrderEvent
  | GAChooseOptionsEvent
  | GACrossSellViewEvent
  | GAViewSearchResultsEvent
  | GADeliveryUnavailableEvent
  | GAViewStoreDetailsEvent
  | GACheckoutErrorEvent
  | GACheckoutPurchaseFailedEvent
  | GACheckoutPurchaseAttemptEvent
  | GACheckoutFiservIFrameErrorEvent
  | GAWingCalculatorCalculateEvent
  | GAWingCalculatorRunEvent
  | GAWingCalculatorOrderEvent
  | GAFavoriteStoreEvent
  | GACallStoreEvent
  | GAOrderClickEvent
  | GAPaymentSaveTapEvent
  | GAProductHomepageClickEvent
  | GASearchEvent
  | GAStoresEnableLocationsEvent
  | GAOptInLocationEvent
  | GAOptOutLocationEvent
  | GAWingCalculatorViewEvent
  | GARecentOrderAgainEvent
  | GAReorderEvent
  | GAChangeHandoff
  | GAStoreOrderNow
  | GABasketCreated
  | GAProductQuantityTap
  | GAButtonClickEvent
  | GALoginCheckoutTapEvent
  | GAStoreLocatorMapExplore
  | GAStoreLocatorMapView
  | GALoyaltyEnrollPrompt
  | GALoyaltyEnrollPromptRespond
  | GALoyaltyOptIn
  | GACarryoutUnavailableEvent
  | GAJoinCrewClick
  | GALoyaltyDashboard;

export type ILegacyAnalyticsService = {
  logGaEvent(event: LogGaEvent): void;
  formatPageUrl(url: string): string | undefined;
};

export const LEGACY_ANALYTICS_SERVICE =
  new InjectionToken<ILegacyAnalyticsService>(
    'Token for the legacy AnalyticsService'
  );

export class LegacyAnalyticsServiceProvider {
  public static get = (
    clazz: Type<ILegacyAnalyticsService>
  ): ClassProvider => ({
    provide: LEGACY_ANALYTICS_SERVICE,
    useClass: clazz
  });
}

@Injectable({ providedIn: 'root' })
export class AnalyticsService implements ILegacyAnalyticsService {
  constructor(
    @Inject(LEGACY_ANALYTICS_SERVICE) private legacy: ILegacyAnalyticsService,
    private featureFlagService: FeatureFlagService
  ) { }

  logGaEvent(event: LogGaEvent): void {
    if (this.featureFlagService.featureFlags.enableDevLogs) {
      console.debug('GA Event:', event);
    }
    this.legacy.logGaEvent(event);
  }

  formatPageUrl(url: string) {
    const replacedSubstr = url
      .substr(url.lastIndexOf('/'))
      .replace('/', '')
      .replace('-', ' ')
      .replace(/(^\w{1})|(\s+\w{1})/g, (word) => word.toUpperCase());

    if (replacedSubstr && replacedSubstr.length) {
      if (!replacedSubstr.includes('?')) {
        if (replacedSubstr === 'Carryout' || replacedSubstr === 'Delivery') {
          return 'Order';
        }
        return replacedSubstr;
      } else if (
        replacedSubstr.includes('?') &&
        replacedSubstr.charAt(0) !== '?'
      ) {
        return replacedSubstr.substr(0, replacedSubstr.indexOf('?'));
      } else if (
        replacedSubstr.includes('?') &&
        replacedSubstr.charAt(0) === '?'
      ) {
        return 'Welcome';
      }
    } else {
      return 'Welcome';
    }
    return replacedSubstr;
  }
}
