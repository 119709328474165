import { Injectable } from '@angular/core';
import { Observable, map } from 'rxjs';

import * as MaybeResponse from '../../types/maybe-response';
import { RewardsOverviewRepository } from '../../repositories/rewards-overview/rewards-overview.repository';
import { RewardsResponseDto } from '../../types/rewards-overview.types';

@Injectable({ providedIn: 'root' })
export class RewardsOverviewService {
	constructor(private rewardsOverviewRepository: RewardsOverviewRepository) { }

	public getData(): Observable<MaybeResponse.MaybeResponse<RewardsResponseDto>> {
		return this.rewardsOverviewRepository.getData().pipe(
			map(MaybeResponse.mapData((data) => {
				if (!data) return undefined;
				return data;
			}))
		)
	}
}