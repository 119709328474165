import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'wri-rewards-point-history',
  templateUrl: './rewards-point-history.component.html',
  styleUrls: ['./rewards-point-history.component.scss']
})
export class RewardsPointHistoryComponent {
  constructor(private router: Router) { }

  public goToRewardsPage() {
    this.router.navigate(['account/rewards']);
  }
}
