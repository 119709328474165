import { Component, OnInit, ViewChild, Inject, AfterViewInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { filter, firstValueFrom, Subscription } from 'rxjs';
import { RewardsPointsWorkflowService } from '../../../../ecomm/workflows/rewards-overview/rewards-overview-workflow.service';
import { RewardProgramDtoType } from '../../../../ecomm/types/rewards-overview.types';
import { Customer, LoyaltyBanner } from '../../../../ecomm/types/customer.types';
import { AnalyticsService } from '../../../../ecomm/providers/legacy-providers/analytics.service';
import { LoyaltyPromptFeature } from '../../../../ecomm/store/features/loyalty-prompt';
import { CustomerFeature, CustomerFeatureState } from '../../../../ecomm/store/features/customer';
import { StoreInfo } from '../../../../ecomm/types/store-info.types';
import { LoyaltyModalComponent } from '../../../common';
import { FeaturesState } from '../../../../ecomm/store/types/features-state';
import { AsynchronousDispatcher } from '../../../../ecomm/utils/asynchronus-dispatcher/asynchronous-dispatcher.service';
import { CustomerWorkflowService } from '../../../../ecomm/workflows/customer/customer-workflow.service';
import { StoreSnapshotService } from '../../../../ecomm/utils/store-snapshot/store-snapshot.service';
import { RedirectService } from '../../../../ecomm/utils/redirect/redirect.service';

@Component({
	selector: 'wri-rewards-page',
	templateUrl: './rewards-page.component.html',
	styleUrls: ['./rewards-page.component.scss']
})
export class RewardsPageComponent
	implements
	OnInit,
	AfterViewInit {
	rewardOverviewData: RewardProgramDtoType;
	userName: string | null | undefined = null;
	public customerState: Customer | null = null;
	public storeInfoState: StoreInfo | null = null;
	private subscription = new Subscription();
	public loyalty: LoyaltyBanner;
	isLoading = false;

	public customerState$ = this.store
		.select(CustomerFeature.selectCustomerState)
		.pipe(filter<CustomerFeatureState>(Boolean));

	@ViewChild(LoyaltyModalComponent) loyaltyModal:
		| LoyaltyModalComponent
		| undefined;

	constructor(
		private rewardWorkflowService: RewardsPointsWorkflowService,
		private analyticsService: AnalyticsService,
		private store: Store,
		@Inject(AsynchronousDispatcher)
		private asynchronusDispatcher: AsynchronousDispatcher<FeaturesState>,
		private userAccountService: CustomerWorkflowService,
		private storeSnapshotService: StoreSnapshotService<FeaturesState>,
		private redirectService: RedirectService,
	) { }

	async ngOnInit(): Promise<void> {
		const data = await this.rewardWorkflowService.rewardOverview();
		this.rewardOverviewData = data;
		this.subscribeToCustomerState();
	}

	async ngAfterViewInit() {
		const loyaltyPrompt = await firstValueFrom(this.store.select(LoyaltyPromptFeature.selectLoyaltyPromptState));
		setTimeout(async () => {
			//LoyaltyPrompt
			if (this.userName && this.loyalty?.eligible && !this.loyalty?.enrolled && !this.loyalty?.optOutDateTime && !loyaltyPrompt.isLoyaltyPromptDisplayed) {
				await this.asynchronusDispatcher.dispatch(
					LoyaltyPromptFeature.actions.setState({ isLoyaltyPromptDisplayed: false })
				);
				this.loyaltyModal?.openModal(this.customerState?.customerId);
			}
		}, 1000);
	}

	async updateCustomer({ eligible, enrolled, optOutDateTime }) {
		const currentStore = await this.storeSnapshotService.get();
		const currentCustomerState = CustomerFeature.selectCustomerState(currentStore);

		await this.asynchronusDispatcher.dispatch(
			CustomerFeature.actions.setState({
				customer: {
					...currentCustomerState.customer,
					loyalty: {
						eligible: eligible,
						enrolled: enrolled,
						optOutDateTime: optOutDateTime
					}
				},
				error: currentCustomerState.error
			})
		)
	}

	onJoinTheCrewClick() {
		if (this.userName && !this.customerState?.loyalty?.enrolled) {
			this.loyaltyModal?.openModal(this.customerState?.customerId);
		}

		this.analyticsService.logGaEvent({
			event: 'join_crew_click'
		})
	}

	private subscribeToCustomerState(): void {
		const customerState$ = this.store
			.select(CustomerFeature.selectCustomerState)
			.pipe(filter(Boolean));

		this.subscription.add(
			customerState$.subscribe((state) => {
				this.customerState = state.customer;
				this.userName = state.customer?.userName;
				this.loyalty = state.customer?.loyalty
			})
		);
	}

	logLoyaltyEnrollPromptResponse(value: string) {
		return this.analyticsService.logGaEvent({
			event: 'loyalty_enroll_prompt_respond',
			user_id: this.customerState?.customerId,
			enroll_response: value
		});
	}

	async onLoyaltyPromptContinueClick({ optedInLoyalty, optedInMarketing }) {
		this.loyaltyModal?.closeModal()
		this.isLoading = true;
		const res = await this.userAccountService.loyaltyEnrollment({ emailPreference: optedInMarketing, enrolled: optedInLoyalty });
		this.isLoading = false;
		if (res) {
			if (optedInLoyalty) {
				this.analyticsService.logGaEvent({
					event: 'loyalty_opt_in',
					user_id: this.customerState?.customerId,
				});
				this.logLoyaltyEnrollPromptResponse('opted_in');
				this.updateCustomer({ eligible: true, enrolled: true, optOutDateTime: null })
			} else {
				this.logLoyaltyEnrollPromptResponse('opted_out');
				this.updateCustomer({ eligible: true, enrolled: false, optOutDateTime: "" })
				this.redirectService.redirectToHome();
			}
		}
	}
}