import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { cacheLoadedGuard } from '../common/guards/cache-loaded.guard';
import { combineGuard } from '../common/guards/combine.guard';
import { ACTIVE_OFFER, ActiveOfferResolver, offlineGuard, signUpGuard } from '../common';
import { ConnectedRewardsPageComponent } from "./components/connected-rewards-page/connected-rewards-page.component";
import { RewardsPointHistoryComponent } from "./components/rewards-point-history/rewards-point-history.component";

const routes: Routes = [
  {
    path: '',
    component: ConnectedRewardsPageComponent,
    canActivate: [
      combineGuard(cacheLoadedGuard, offlineGuard, signUpGuard)
    ],
    resolve: {
      [ACTIVE_OFFER]: ActiveOfferResolver
    },
    data: {
      reuse: true
    }
  },
  {
    path: 'history',
    component: RewardsPointHistoryComponent
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)]
})
export class RewardsPageRouting { }
