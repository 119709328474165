<div class="rewards-wpr">
  <div class="rewards-wpr-points">
    <div class="rewards-wpr-points-number">
      {{ availablePoints$ | async }}
    </div>
    <div class="rewards-wpr-points-label">POINTS</div>
  </div>
  <div class="rewards-wpr-overview">
    <wri-icon class="icon" icon="wri-info"></wri-icon>
    Learn more about
    <a class="rewards-link"> Wingstop Rewards</a>
  </div>
  <div class="rewards-history-link">
    <a routerLink="history" class="history-link">History</a>
  </div>
</div>
