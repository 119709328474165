import { Injectable } from '@angular/core';
import { tap } from 'rxjs';

import * as Workflow from '../../types/workflow';
import { NotificationService } from '../../utils/notification/notification.service';
import { UNKNOWN_ERROR } from "../../utils/throw-error";
import { RewardsOverviewService } from '../../services/rewards-overview/rewards-overview.service';

@Injectable({ providedIn: 'root' })
export class RewardsPointsWorkflowService {
	constructor(
		private rewardsOverviewService: RewardsOverviewService,
		private notificationService: NotificationService,
	) { }

	private reportErrors = <T>() =>
		Workflow.onError<T>(
			tap((res) => {
				this.notificationService.showError(res?.error ?? UNKNOWN_ERROR);
			})
		);

	public rewardOverview = () =>
		Workflow.createWorkflow(
			undefined,
			() => this.rewardsOverviewService.getData(),
			this.reportErrors()
		)();
}