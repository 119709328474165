import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { MaybeResponse } from '../../types/maybe-response';
import { ECOMM_API_CONFIG, EcommAPIConfig } from '../../config/ecomm-config.provider';

@Injectable({ providedIn: 'root' })
export class RewardsPointsRepository {
  constructor(
    private http: HttpClient,
    @Inject(ECOMM_API_CONFIG) private config: EcommAPIConfig
  ) {}

  public fetchPoints(): Observable<MaybeResponse<{ availablePoints: number }>> {
    const url = `${this.config.baseUrl}/api/customer/loyalty`;
    return this.http.get<MaybeResponse<{ availablePoints: number }>>(url).pipe(
      map(response => ({
        data: { availablePoints: response.data?.availablePoints},
        error: response.error
      }))
    );
  }
}
