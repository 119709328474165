import {
  createActionGroup,
  createFeature,
  createReducer,
  emptyProps,
  on,
  props,
} from '@ngrx/store';

export type RewardsPoints = {
  availablePoints: number;
};

export type RewardsPointsFeatureState = RewardsPoints;

const initialRewardsPointsFeatureState: RewardsPointsFeatureState = {
  availablePoints: 0,
};

const RewardsPointsActions = createActionGroup({
  source: 'Rewards Points Feature',
  events: {
    'Set Points': props<{ availablePoints: number }>(),
    'Reset To Default': emptyProps(),
  },
});

const RewardsPointsReducer = createReducer(
  initialRewardsPointsFeatureState,
  on(
    RewardsPointsActions.setPoints,
    (state, action): RewardsPointsFeatureState => ({
      ...state,
      availablePoints: action.availablePoints ?? 0,
    })
  ),
  on(
    RewardsPointsActions.resetToDefault,
    (state): RewardsPointsFeatureState => ({
      ...state,
      ...initialRewardsPointsFeatureState,
    })
  )
);

const RewardsPointsFeatureKey = 'rewardsPoints';

const RewardsPointsFeature = createFeature({
  name: RewardsPointsFeatureKey,
  reducer: RewardsPointsReducer,
});

export default {
  ...RewardsPointsFeature,
  actions: RewardsPointsActions,
  initialState: initialRewardsPointsFeatureState,
};
