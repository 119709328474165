import { CommonModule } from "@angular/common";
import { ModuleWithProviders, NgModule, Type } from "@angular/core";
import { RouterModule } from "@angular/router";
import { NgxSliderModule } from "ngx-slider-v2";

import { EcommAPIConfigProvider } from "../../ecomm/config/ecomm-config.provider";
import { EcommFeatureFlagsProvider } from "../../ecomm/config/ecomm-feature-flags.provider";
import { EcommModule } from "../../ecomm/ecomm.module";
import { Config, ConfigProvider } from "../../ecomm/providers/config/config.provider";
import { ILegacyAnalyticsService, LegacyAnalyticsServiceProvider } from "../../ecomm/providers/legacy-providers/analytics.service";
import { ILegacySeoService, LegacySeoServiceProvider } from "../../ecomm/providers/legacy-providers/seo.service";
import { WriCommonModule } from "../common/common.module";
import { ConnectedRewardsPageComponent } from './components/connected-rewards-page/connected-rewards-page.component';
import { RewardsPageRouting } from "./rewards.routes";
import { RewardsPageComponent } from "./components/rewards-page/rewards-page.component";
import { RewardsPointsPageComponent } from "./components/rewards-points-page/rewards-points-page.component";
import { RewardsPointHistoryComponent } from './components/rewards-point-history/rewards-point-history.component';

const components = [
  ConnectedRewardsPageComponent,
  RewardsPageComponent,
  RewardsPointsPageComponent,
  RewardsPointHistoryComponent
]

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    NgxSliderModule,
    WriCommonModule,
    EcommModule,
    RewardsPageRouting
  ],
  declarations: [...components],
  providers: [],
  exports: components
})
export class RewardsModule {
  public static withConfig(
    config: Config,
    legacy: {
      legacyAnalyticsServiceClass: Type<ILegacyAnalyticsService>;
      legacySeoServiceClass: Type<ILegacySeoService>;
    }
  ): ModuleWithProviders<RewardsModule> {
    return {
      ngModule: RewardsModule,
      providers: [
        ConfigProvider.get(config),
        EcommAPIConfigProvider.get(config.ecomm),
        EcommFeatureFlagsProvider.get(config.featureFlags),
        LegacyAnalyticsServiceProvider.get(legacy.legacyAnalyticsServiceClass),
        LegacySeoServiceProvider.get(legacy.legacySeoServiceClass)
      ]
    };
  }
}
