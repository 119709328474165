import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import * as MaybeResponse from '../../types/maybe-response';
import { handleResourceResponse } from '../../utils/handle-resource-response';
import { RewardsResponseDto } from '../../types/rewards-overview.types';
import { WINDOW } from '../../providers/window/window.provider';

@Injectable({ providedIn: 'root' })
export class RewardsOverviewRepository {

	constructor(
		@Inject(WINDOW) private window: Window,
		private http: HttpClient,
	) { }

	getData(): Observable<MaybeResponse.MaybeResponse<RewardsResponseDto>> {
		const url = `${this.window.location.origin}/resources/loyalty-program-overview.json`;
		return this.http
			.get<RewardsResponseDto>(url)
			.pipe(handleResourceResponse(RewardsResponseDto));
	}
}