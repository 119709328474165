import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { OfflineModule } from './offline.module'
import { ConnectedOfflinePageComponent } from './components/connected-offline-page/connected-offline-page.component';
import { combineGuard } from '../common/guards/combine.guard';
import { cacheLoadedGuard } from '../common/guards/cache-loaded.guard';

const offlineRoutes: Routes = [
	{
		path: '',
		component: ConnectedOfflinePageComponent,
		canActivate: [
			combineGuard(cacheLoadedGuard)
		],
		data: {
			reuse: true
		}
	}
]
@NgModule({
	imports: [OfflineModule, RouterModule.forChild(offlineRoutes)]
})
export class OfflineRoutingModule { }
