import { Inject, Injectable } from '@angular/core';
import {  tap } from 'rxjs';

import { RewardsPointsService } from '../../services/rewards-points/rewards-points.service';
import { RewardsPointsFeature } from '../../store/features/rewards-points';
import { FeaturesState } from '../../store/types/features-state';
import * as Workflow from '../../types/workflow';
import { asyncTap } from '../../utils/async-tap';
import { AsynchronousDispatcher } from '../../utils/asynchronus-dispatcher/asynchronous-dispatcher.service';
import { NotificationService } from '../../utils/notification/notification.service';
import { StoreSnapshotService } from '../../utils/store-snapshot/store-snapshot.service';
import {UNKNOWN_ERROR} from "../../utils/throw-error";

@Injectable({providedIn: 'root'})
export class RewardsPointsWorkflowService {
  private saveRewardsPointsState = Workflow.onAny<number>(
    tap((points) => {
      this.asynchronusDispatcher.dispatch(
        RewardsPointsFeature.actions.setPoints({
          availablePoints: points.data
        })
      );
    })
  );

  constructor(
    private rewardsPointsService: RewardsPointsService,
    private notificationService: NotificationService,
    @Inject(AsynchronousDispatcher)
    private asynchronusDispatcher: AsynchronousDispatcher<FeaturesState>,
    @Inject(StoreSnapshotService)
    private storeSnapshotService: StoreSnapshotService<FeaturesState>
  ) {}

  private reportErrors = <T>() =>
    Workflow.onError<T>(
      tap((res) => {
        this.notificationService.showError(res?.error ?? UNKNOWN_ERROR);
      })
    );

  private savePointsToStore = Workflow.onAny<number>(
    asyncTap((res) =>{
      return this.asynchronusDispatcher.dispatch(
        RewardsPointsFeature.actions.setPoints({
          availablePoints: res.data
        })
      )
    }
    )
  );

  public getRewardsPoints = Workflow.createWorkflow(
    undefined,
    () => this.rewardsPointsService.getRewardsPoints(),
    this.reportErrors(),
    this.savePointsToStore

  );

}
