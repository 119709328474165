import {
  PreloadAllModules,
  RouterModule,
  Routes,
  UrlSegment,
} from '@angular/router';

import { NgModule } from '@angular/core';
import { NotFoundComponent } from '@wingstop/global/not-found/not-found.component';

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./lib/ngfe-app/src/public-api').then(m => m.HomePageRoutingModule)
  },
  {
    matcher: thighstopMatcher,
    redirectTo: 'menu',
  },
  {
    path: 'location',
    loadChildren: () =>
      import('./lib/ngfe-app/src/public-api').then((m) => m.LocationMenuRoutingModule),
  },
  {
    path: 'locations',
    loadChildren: () =>
      import('./lib/ngfe-app/src/public-api').then((m) => m.LocationMenuRoutingModule),
  },
  {
    path: 'order',
    loadChildren: () =>
      import('./lib/ngfe-app/src/public-api').then((m) => m.OrderRoutingModule),
  },
  {
    path: 'account',
    loadChildren: () =>
      import('./lib/ngfe-app/src/public-api').then((m) => m.AccountsRoutingModule),
  },
  {
    path: 'auth',
    loadChildren: () =>
      import('./lib/ngfe-app/src/public-api').then((m) => m.AccountsRoutingModule),
  },
  {
    path: 'menu',
    loadChildren: () =>
      import('./lib/ngfe-app/src/public-api').then((m) => m.GlobalMenuRoutingModule),
  },
  {
    path: 'job-search',
    loadChildren: () => import('./lib/ngfe-app/src/public-api').then(m => m.JobSearchRoutingModule)
  },
  {
    path: 'careers',
    loadChildren: () =>
      import('./modules/careers/careers.module').then((m) => m.CareersModule),
  },
  {
    path: 'account/rewards',
    loadChildren: () =>
      import('./lib/ngfe-app/src/public-api').then((m) => m.RewardsModule),
  },
  {
    path: 'flavors',
    loadChildren: () =>
      import('./lib/ngfe-app/src/public-api').then((m) => m.FlavorsModule),
  },
  {
    path: 'offline',
    loadChildren: () =>
      import('./lib/ngfe-app/src/public-api').then((m) => m.OfflineRoutingModule),
  },
  {
    path: 'privacy',
    loadChildren: () =>
      import('./modules/privacy/privacy.module').then((m) => m.PrivacyModule),
  },
  {
    path: 'california-privacy',
    loadChildren: () =>
      import('./modules/privacy/privacy.module').then((m) => m.PrivacyModule),
    data: { privacy_type: 'caliConsumerPrivacy' },
  },
  {
    path: 'terms-of-use',
    loadChildren: () =>
      import('./modules/terms/terms.module').then((m) => m.TermsModule),
  },
  {
    path: 'door-dash-opt-out',
    loadChildren: () =>
      import('./modules/door-dash/door-dash.module').then(
        (m) => m.DoorDashModule
      ),
  },
  {
    path: 'sitemap',
    loadChildren: () =>
      import('./modules/global/global.module').then((m) => m.GlobalModule)
  },
  {
    path: '**',
    component: NotFoundComponent
  }
];

export function thighstopMatcher(segments: UrlSegment[]) {
  return segments.length >= 2 &&
    segments[0].path.match(/location/) &&
    segments[1].path.match(/thighstop/g)
    ? { consumed: segments }
    : null;
}

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      enableTracing: false,
      anchorScrolling: 'enabled',
      onSameUrlNavigation: 'ignore',
      scrollPositionRestoration: 'enabled',
      preloadingStrategy: PreloadAllModules,
    }),
  ],
  exports: [RouterModule],
})
export class AppRouting { }
