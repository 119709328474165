import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { ModuleWithProviders, NgModule, Type } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { CookieService } from 'ngx-cookie-service';
import { ToastrModule } from 'ngx-toastr';

import { EcommAPIConfigProvider } from './config/ecomm-config.provider';
import { EcommFeatureFlagsProvider } from './config/ecomm-feature-flags.provider';
import { ApplePayProviderProvider } from './providers/apple-pay/apple-pay.provider';
import { BrainTreeProvider } from './providers/brain-tree/brain-tree.provider';
import { Config, ConfigProvider } from './providers/config/config.provider';
import { DocumentProvider } from './providers/document/document.provider';
import {
  AnalyticsService,
  ILegacyAnalyticsService,
  LegacyAnalyticsServiceProvider,
} from './providers/legacy-providers/analytics.service';
import {
  ILegacySeoService,
  LegacySeoServiceProvider,
} from './providers/legacy-providers/seo.service';
import { MapboxProvider } from './providers/mapbox/mapbox.provider';
import { NavigatorProvider } from './providers/navigator/navigator.provider';
import { ScriptLoaderProvider } from './providers/script-loader/script-loader.provider';
import { StorageProvider } from './providers/storage/storage.provider';
import { UcomSDKProvider } from './providers/ucom-sdk/ucom-sdk.provider';
import { WindowProvider } from './providers/window/window.provider';
import { CartRepository } from './repositories/cart/cart.repository';
import { FlavorsRepository } from './repositories/flavors/flavors.repository';
import { OrderRepository } from './repositories/order/order.repository';
import { PaymentRepository } from './repositories/payment/payment.repository';
import { RegionalConfigurationRepository } from './repositories/regional-configuration/regional-configuration.repository';
import { StoreInfoRepository } from './repositories/store-info/store-info.repository';
import { CartService } from './services/cart/cart.service';
import { FlavorsService } from './services/flavors/flavors.service';
import { OrderService } from './services/order/order.service';
import { PaymentService } from './services/payment/payment.service';
import { RegionalConfigurationService } from './services/regional-configuration/regional-configuration.service';
import { StoreInfoService } from './services/store-info/store-info.service';
import { ActiveOfferFeature } from './store/features/active-offer';
import { AuthFeature } from './store/features/auth';
import { CacheLoadedFeature } from './store/features/cache-loaded';
import { CartFeature } from './store/features/cart';
import { CustomerFeature } from './store/features/customer';
import { OrderFeature } from './store/features/order';
import { RegionalConfigurationFeature } from './store/features/regional-configuration';
import { SelectedHandoffModeFeature } from './store/features/selected-handoff-mode';
import { StoreInfoFeature } from './store/features/store-info';
import { DispatchReporterMiddlewareProvider } from './store/middleware/dispatch-reporter';
import { FeatureHydrationMiddlewareProvider } from './store/middleware/feature-hydration';
import { WindowWSUpdaterMiddlewareProvider } from './store/middleware/window-ws-updater';
import { AsynchronousDispatcherProvider } from './utils/asynchronus-dispatcher/asynchronous-dispatcher.service';
import { CurrentUrlService } from './utils/current-url/current-url.service';
import { FeatureFlagService } from './utils/feature-flag/feature-flag.service';
import { FeesCalculationService } from './utils/fees-calculation/fees-calculation';
import { AuthInterceptor } from './utils/interceptor/auth.interceptor';
import { NotificationService } from './utils/notification/notification.service';
import { ReCaptchaService } from './utils/recaptcha/recaptcha.service';
import { RedirectService } from './utils/redirect/redirect.service';
import { StoreSnapshotServiceProvider } from './utils/store-snapshot/store-snapshot.service';
import { WebSocketClient } from './web-socket/web-socket.client';
import { WebSocketProvider } from './web-socket/web-socket.provider';
import { CartWorkflowService } from './workflows/cart/cart-workflow.service';
import { CustomerWorkflowService } from './workflows/customer/customer-workflow.service';
import { FlavorsWorkflowService } from './workflows/flavors/flavors-workflow.service';
import { OrderWorkflowService } from './workflows/order/order-workflow.service';
import { PaymentWorkflowService } from './workflows/payment/payment-workflow.service';
import { SearchLocationWorkflowService } from './workflows/search-location/search-location-workflow.service';
import { SelectedHandoffModeService } from './workflows/selected-handoff-mode/selected-handoff-mode.service';
import { StoreInfoWorkflowService } from './workflows/store-info/store-info-workflow.service';
import { HttpResponseInterceptor } from './utils/interceptor/http-response.interceptor';
import { RewardsPointsWorkflowService } from './workflows/rewards-overview/rewards-overview-workflow.service'
import { RewardsPointsFeature } from "./store/features/rewards-points";
import { JWTRepository } from "./repositories/jwt/jwt.repository";
import { JWTService } from "./services/jwt/jwt.service";
import { JWTWorkflowService } from "./workflows/jwt/jwt-workflow.service";

@NgModule({
  imports: [
    HttpClientModule,
    ToastrModule.forRoot({
      positionClass: 'toast-top-center',
      closeButton: true,
      timeOut: 6000,
      maxOpened: 1,
      enableHtml: true,
      autoDismiss: true,
    }),
    StoreModule.forFeature(ActiveOfferFeature),
    StoreModule.forFeature(AuthFeature),
    StoreModule.forFeature(CacheLoadedFeature),
    StoreModule.forFeature(CartFeature),
    StoreModule.forFeature(CustomerFeature),
    StoreModule.forFeature(OrderFeature),
    StoreModule.forFeature(RegionalConfigurationFeature),
    StoreModule.forFeature(SelectedHandoffModeFeature),
    StoreModule.forFeature(StoreInfoFeature),
    StoreModule.forFeature(RewardsPointsFeature)
  ],
  providers: [
    /** intercepters */
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpResponseInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },

    /** utils */
    AnalyticsService,
    CookieService,
    CurrentUrlService,
    FeatureFlagService,
    FeesCalculationService,
    NotificationService,
    ReCaptchaService,
    RedirectService,

    /** clients */
    WebSocketClient,

    /** repositories */
    CartRepository,
    FlavorsRepository,
    PaymentRepository,
    OrderRepository,
    RegionalConfigurationRepository,
    StoreInfoRepository,
    JWTRepository,

    /** services */
    CartService,
    FlavorsService,
    PaymentService,
    OrderService,
    RegionalConfigurationService,
    StoreInfoService,
    JWTService,

    /** providers */
    ApplePayProviderProvider.get(),
    BrainTreeProvider.get(),
    DocumentProvider.get(),
    MapboxProvider.get(),
    NavigatorProvider.get(),
    ScriptLoaderProvider.get(),
    StorageProvider.get(),
    UcomSDKProvider.get(),
    WebSocketProvider.get(),
    WindowProvider.get(),

    // Store Middleware
    DispatchReporterMiddlewareProvider.get(),
    FeatureHydrationMiddlewareProvider.get(),
    WindowWSUpdaterMiddlewareProvider.get(),

    // Store Utilities
    AsynchronousDispatcherProvider.get(),
    StoreSnapshotServiceProvider.get(),

    // Workflows
    CartWorkflowService,
    CustomerWorkflowService,
    FlavorsWorkflowService,
    OrderWorkflowService,
    PaymentWorkflowService,
    SearchLocationWorkflowService,
    SelectedHandoffModeService,
    StoreInfoWorkflowService,
    RewardsPointsWorkflowService,
    JWTWorkflowService
  ],
})
export class EcommModule {
  public static withConfig(
    config: Config,
    legacy: {
      legacyAnalyticsServiceClass: Type<ILegacyAnalyticsService>;
      legacySeoServiceClass: Type<ILegacySeoService>;
    }
  ): ModuleWithProviders<EcommModule> {
    return {
      ngModule: EcommModule,
      providers: [
        ConfigProvider.get(config),
        EcommAPIConfigProvider.get(config.ecomm),
        EcommFeatureFlagsProvider.get(config.featureFlags),
        LegacyAnalyticsServiceProvider.get(legacy.legacyAnalyticsServiceClass),
        LegacySeoServiceProvider.get(legacy.legacySeoServiceClass),
      ],
    };
  }
}
