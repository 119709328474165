import { inject } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { firstValueFrom } from 'rxjs';

import { CustomerFeature } from '../../../ecomm/store/features/customer';
import { RedirectService } from '../../../ecomm/utils/redirect/redirect.service';

export const signUpGuard = async () => {
  const store = inject(Store);
  const redirectService = inject(RedirectService);

  const customerState = await firstValueFrom(
    store.pipe(select(CustomerFeature.selectCustomer))
  );

  if (!customerState?.email) {
    await redirectService.redirectToSignUp();
    return false;
  }

  if (customerState && !customerState.loyalty.eligible) {
    await redirectService.redirectToHome();
    return false;
  }

  return true;
};