<section class="rewards-link-container">
      <a
        wriFocusOutline
        tabindex="0"
        (click)="goToRewardsPage()"
        class="rewards-link"
      >
        <wri-icon
          icon="wri-arrow-back"
          class="arrow-left-icon"
        ></wri-icon>
        <span>Back to Rewards</span
        >
      </a>
  </section>