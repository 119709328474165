import ActiveOfferFeature, {
  ActiveOfferFeatureState
} from '../features/active-offer';
import { AuthFeature, AuthFeatureState } from '../features/auth';
import {
  CacheLoadedFeature,
  CacheLoadedFeatureState
} from '../features/cache-loaded';
import { CartFeature, CartFeatureState } from '../features/cart';
import { CustomerFeature, CustomerFeatureState } from '../features/customer';
import { LocationDetailsFeature, LocationDetailsFeatureState } from '../features/location-details';
import { OrderFeature, OrderFeatureState } from '../features/order';
import {
  RegionalConfigurationFeature,
  RegionalConfigurationFeatureState
} from '../features/regional-configuration';
import {
  SelectedHandoffModeFeature,
  SelectedHandoffModeFeatureState
} from '../features/selected-handoff-mode';
import {
  StoreInfoFeature,
  StoreInfoFeatureState
} from '../features/store-info';
import { LoyaltyPromptFeature, LoyaltyPromptFeatureState } from '../features/loyalty-prompt';
import { RewardsPointsFeature, RewardsPointsFeatureState } from '../features/rewards-points';

export type FeaturesState = {
  [CartFeature.name]: CartFeatureState;
  [CustomerFeature.name]: CustomerFeatureState;
  [SelectedHandoffModeFeature.name]: SelectedHandoffModeFeatureState;
  [AuthFeature.name]: AuthFeatureState;
  [OrderFeature.name]: OrderFeatureState;
  [CacheLoadedFeature.name]: CacheLoadedFeatureState;
  [ActiveOfferFeature.name]: ActiveOfferFeatureState;
  [StoreInfoFeature.name]: StoreInfoFeatureState;
  [RegionalConfigurationFeature.name]: RegionalConfigurationFeatureState;
  [LocationDetailsFeature.name]: LocationDetailsFeatureState;
  [LoyaltyPromptFeature.name]: LoyaltyPromptFeatureState;
  [RewardsPointsFeature.name]: RewardsPointsFeatureState;
};

export const initialFeaturesState: FeaturesState = {
  [CartFeature.name]: CartFeature.initialState,
  [CustomerFeature.name]: CustomerFeature.initialState,
  [SelectedHandoffModeFeature.name]: SelectedHandoffModeFeature.initialState,
  [OrderFeature.name]: OrderFeature.initialState,
  [AuthFeature.name]: AuthFeature.initialState,
  [CacheLoadedFeature.name]: CacheLoadedFeature.initialState,
  [ActiveOfferFeature.name]: ActiveOfferFeature.initialState,
  [StoreInfoFeature.name]: StoreInfoFeature.initialState,
  [RegionalConfigurationFeature.name]: RegionalConfigurationFeature.initialState,
  [LocationDetailsFeature.name]: LocationDetailsFeature.initialState,
  [LoyaltyPromptFeature.name]: LoyaltyPromptFeature.initialState,
  [RewardsPointsFeature.name]: RewardsPointsFeature.initialState
};
