import { Injectable } from '@angular/core';
import { Observable} from 'rxjs';
import { map } from 'rxjs/operators';
import { RewardsPointsRepository } from '../../repositories/rewards-points/rewards-points.repository';
import * as MaybeResponse from '../../types/maybe-response';

@Injectable({ providedIn: 'root' })
export class RewardsPointsService {
  constructor(private rewardsPointsRepository: RewardsPointsRepository) {}

  public getRewardsPoints(): Observable<MaybeResponse.MaybeResponse<number>> {
    return this.rewardsPointsRepository.fetchPoints().pipe(
      map(response => ({
        ...response,
        data: response.data?.availablePoints
      }))
    );
  }
}
