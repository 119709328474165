<ng-template #commonModal let-close="close" let-dismiss="dismiss">
  <wri-common-modal [close]="close" [dismiss]="dismiss">
    <div class="loyalty-modal">
      <div class="modal-image" id="wri-loyalty-modal-img">
        <img src="assets/club-wingstop.svg" alt="Club Image" />
      </div>

      <div class="modal-body">
        <div class="switch-wpr">
          <label class="switch">
            <input type="checkbox" [(ngModel)]="optedInLoyalty" />
            <span class="slider round"></span>
          </label>
          <div class="switch-label" id="wri-opt-in-toggle-text">
            Opt in to earn points, play games,
            <br />
            and receive rewards, deals, and
            <br />
            exclusive promotions
          </div>
        </div>
        <div class="switch-wpr">
          <label class="switch">
            <input type="checkbox" [(ngModel)]="optedInMarketing" />
            <span class="slider round"></span>
          </label>
          <div class="switch-label" id="wri-comms-toggle-text">
            Sign up to receive emails about
            <br />
            rewards, deals, and exclusive
            <br />
            promotions
          </div>
        </div>
      </div>
      <button class="wri-btn wri-btn-primary" (click)="onContinue()">
        Continue
      </button>
    </div>
  </wri-common-modal>
</ng-template>
