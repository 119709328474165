import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { LoyaltyBanner } from "../../../../ecomm/types/customer.types";
import { CustomerFeature } from "../../../../ecomm/store/features/customer";
import { filter, Subscription } from "rxjs";
import { Store } from "@ngrx/store";
import { RedirectService } from "../../../../ecomm/utils/redirect/redirect.service";
import { FeatureFlagService } from "../../../../ecomm/utils/feature-flag/feature-flag.service";
import { CustomerWorkflowService } from '../../../../ecomm/workflows/customer/customer-workflow.service';

@Component({
  selector: 'app-connected-rewards-page',
  templateUrl: './connected-rewards-page.component.html',
})
export class ConnectedRewardsPageComponent implements OnInit, OnDestroy {
  public loyalty: LoyaltyBanner;
  public enableGuestRewardsMenu: boolean;
  private subscription = new Subscription();
  constructor(
    private store: Store,
    @Inject(RedirectService)
    private redirectService: RedirectService,
    private featureFlagService: FeatureFlagService,
    private userAccountService: CustomerWorkflowService,
  ) {
  }
  async ngOnInit(): Promise<void> {
    await this.userAccountService.getUserAccount();
    this.subscribeToCustomerState();

    const featureFlagPromise = this.featureFlagService.getFeatureFlagValue(
      'enableGuestRewardsMenu',
      'feature_guest_rewards_menu'
    );

    this.enableGuestRewardsMenu = await featureFlagPromise;

    if ((!this.loyalty.enrolled && !this.enableGuestRewardsMenu) || !this.loyalty.eligible) {
      this.redirectService.redirectToHome();
    }
  }

  private subscribeToCustomerState(): void {
    const customerState$ = this.store
      .select(CustomerFeature.selectCustomerState)
      .pipe(filter(Boolean));

    this.subscription.add(
      customerState$.subscribe((state) => {
        this.loyalty = state.customer?.loyalty
      })
    );
  }
  ngOnDestroy(): void {
    if (this.subscription && !this.subscription.closed) {
      this.subscription.unsubscribe();
    }
  }
}
