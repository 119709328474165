<wri-page-loading-indicator *ngIf="isLoading"></wri-page-loading-indicator>
<div class="rewards-wrapper" *ngIf="rewardOverviewData">
  <div class="top-section">
    <img
      class="wingstop-animation"
      [src]="rewardOverviewData?.hero?.imageUrl"
      [alt]="rewardOverviewData?.hero?.imageAltText"
      tabindex="0"
      role="img" />
    <div
      class="right-section"
      [ngStyle]="{
        'background-color': rewardOverviewData?.hero?.backgroundColor
      }">
      <span class="section-title" tabindex="0">
        {{ rewardOverviewData?.hero?.title }}
      </span>
      <span
        tabindex="0"
        class="section-description"
        [ngClass]="{
          'section-description-invisible': userName && loyalty?.enrolled
        }">
        {{ rewardOverviewData?.hero?.description }}
      </span>
      <button
        class="wri-btn"
        [ngClass]="{
          'wri-btn-primary':
            rewardOverviewData?.hero?.buttonStyle === 'primary',
          'wri-btn-secondary':
            rewardOverviewData?.hero?.buttonStyle === 'secondary'
        }"
        (click)="onJoinTheCrewClick()"
        *ngIf="userName && !loyalty?.enrolled">
        {{ rewardOverviewData?.ctaLabel }}
      </button>
    </div>
  </div>
  <div class="mid-section">
    <span class="hero-title" tabindex="0">
      {{ rewardOverviewData?.benefits?.title }}
    </span>
    <span class="hero-description" tabindex="0">
      {{ rewardOverviewData?.benefits?.description }}
    </span>
    <div class="mid-bottom">
      <div
        class="benefit-cards"
        *ngFor="let card of rewardOverviewData?.benefits?.cards">
        <div class="image-wrapper">
          <img [src]="card.iconUrl" *ngIf="card.iconUrl" />
        </div>
        <div class="text">
          <span class="card-title" tabindex="0">{{ card.title }}</span>
          <span class="card-description" *ngIf="card.description" tabindex="0">
            {{ card.description }}
          </span>
        </div>
      </div>
    </div>
  </div>
  <div class="steps-section">
    <span class="heading" tabindex="0">Get rewarded in 3 easy steps</span>
    <div class="steps-container">
      <div
        class="steps"
        *ngFor="let step of rewardOverviewData?.steps; let i = index">
        <img
          [src]="step.imageUrl"
          [alt]="step.imageAltText"
          tabindex="0"
          *ngIf="step.imageUrl" />

        <div class="steps-content">
          <div class="image-wrapper">
            <img *ngIf="step.iconUrl" [src]="step.iconUrl" />
          </div>
          <div class="content">
            <span class="steps-title" tabindex="0">
              {{ step.title }}
            </span>
            <span class="steps-description" tabindex="0">
              {{ step.description }}
            </span>
          </div>
        </div>
      </div>
    </div>
    <button
      class="wri-btn"
      [ngClass]="{
        'wri-btn-primary': rewardOverviewData?.hero?.buttonStyle === 'primary',
        'wri-btn-secondary':
          rewardOverviewData?.hero?.buttonStyle === 'secondary'
      }"
      (click)="onJoinTheCrewClick()"
      *ngIf="userName && !loyalty?.enrolled">
      {{ rewardOverviewData?.ctaLabel }}
    </button>
  </div>
  <ng-container *ngIf="rewardOverviewData?.disclaimer">
    <div class="disclaimers-wrapper">
      <div class="disclaimers-inner-wrapper">
        <wri-markdown
          class="disclaimer-text"
          [raw]="rewardOverviewData.disclaimer"></wri-markdown>
      </div>
    </div>
  </ng-container>
</div>
<wri-loyalty-modal
  (continueButtonClick)="
    onLoyaltyPromptContinueClick($event)
  "></wri-loyalty-modal>
