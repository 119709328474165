import * as t from 'io-ts';

/* #region RewardProgram */
export const HeroDto = t.type({
	title: t.string,
	description: t.string,
	imageUrl: t.string,
	imageAltText: t.string,
	style: t.string,
	backgroundColor: t.string,
	buttonStyle: t.string,
}, 'HeroDto');

export const Cards = t.type({
	title: t.string,
	description: t.string,
	iconUrl: t.string
})

export const BenefitsDto = t.type({
	title: t.string,
	description: t.string,
	cards: t.array(Cards)
}, 'BenefitCardDto');

export const StepDto = t.type({
	title: t.string,
	description: t.string,
	imageUrl: t.string,
	imageAltText: t.string,
	iconUrl: t.string
}, 'StepDto');

export const RewardProgramDto = t.type({
	hero: HeroDto,
	benefits: BenefitsDto,
	steps: t.array(StepDto),
	ctaLabel: t.string,
	enrolledCtaLabel: t.string,
	disclaimer: t.string
}, 'RewardProgramDto');

export type RewardProgramDtoType = t.TypeOf<typeof RewardProgramDto>;
export type RewardProgram = RewardProgramDtoType;
/* #endregion */

/* #region RewardProgramResponse */
export const RewardsResponseDto = t.type(
	{
		hero: HeroDto,
		benefits: BenefitsDto,
		steps: t.array(StepDto),
		ctaLabel: t.string,
		enrolledCtaLabel: t.string,
		disclaimer: t.string,
	},
	'RewardsResponse'
);

export type RewardsResponseDto = t.TypeOf<typeof RewardsResponseDto>;
/* #endregion */
