import { Component, OnInit, OnChanges, SimpleChanges, Input } from '@angular/core';
import { Store } from '@ngrx/store';
import { RewardsPointsFeature } from '../../../../ecomm/store/features/rewards-points';
import { RewardsPointsWorkflowService } from '../../../../ecomm/workflows/rewards-points/rewards-points-workflow';
import { LoyaltyBanner } from '../../../../ecomm/types/customer.types';
import { Observable } from 'rxjs';
import {AnalyticsService} from "../../../../ecomm/providers/legacy-providers/analytics.service";

@Component({
  selector: 'app-rewards-points-page',
  templateUrl: './rewards-points-page.component.html',
  styleUrls: ['./rewards-points-page.component.scss'],
})
export class RewardsPointsPageComponent implements OnInit, OnChanges {
  @Input() loyalty: LoyaltyBanner;
  availablePoints$: Observable<number>;

  constructor(
    private store: Store,
    private rewardsPointsWorkflowService: RewardsPointsWorkflowService,
    private analyticsService: AnalyticsService,
  ) {}

  async ngOnInit() {
    this.availablePoints$ = this.store.select(RewardsPointsFeature.selectAvailablePoints);
    await this.rewardsPointsWorkflowService.getRewardsPoints();
    this.logGaEvent();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.loyalty && this.loyalty?.enrolled) {
      this.availablePoints$ = this.store.select(RewardsPointsFeature.selectAvailablePoints);
      this.logGaEvent();
    }
  }

  private logGaEvent() {
   this.analyticsService.logGaEvent({ event: 'loyalty_dashboard' });
  }
}
