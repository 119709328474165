import { Inject, Injectable, Provider } from '@angular/core';
import {
  Actions,
  concatLatestFrom,
  createEffect,
  ofType,
  ROOT_EFFECTS_INIT
} from '@ngrx/effects';
import {
  Action,
  ActionReducer,
  ActionReducerMap,
  createAction,
  META_REDUCERS,
  props,
  Store
} from '@ngrx/store';
import { cloneDeep, omit, pick } from 'lodash';
import { exhaustMap, filter, from, map, Observable, take } from 'rxjs';
import localForage from 'localforage';

import { providedOnceMiddleware } from './provided-once-middleware';
import { bufferWhileBusy } from '../../utils/buffer-while-busy';
import { RegionalConfigurationFeature } from '../features/regional-configuration';
import { ActiveOfferFeature } from '../features/active-offer';
import { AuthFeature } from '../features/auth';
import { CacheLoadedFeature } from '../features/cache-loaded';
import { CartFeature } from '../features/cart';
import { CustomerFeature } from '../features/customer';
import { StoreInfoFeature } from '../features/store-info';
import { SelectedHandoffModeFeature } from '../features/selected-handoff-mode';
import { DeepPartial } from '../../types/deep-partial';
import { FeaturesState, initialFeaturesState } from '../types/features-state';
import { OrderFeature } from '../features/order';
import { STORAGE } from '../../providers/storage/storage.provider';
import { deepMergeAll } from '../../utils/deep-merge-all';
import { LocationDetailsFeature } from '../features/location-details';
import { LoyaltyPromptFeature } from '../features/loyalty-prompt';
import { RewardsPointsFeature } from '../features/rewards-points';

export const FEATURE_HYDRATION_KEY = 'persist:features';

const saveSubsetFilter = {
  [CartFeature.name]: ['cart'],
  [SelectedHandoffModeFeature.name]: ['address', 'handoffMode'],
  [OrderFeature.name]: ['lastSessionOrder'],
  [StoreInfoFeature.name]: ['storeInfo'],
  [ActiveOfferFeature.name]: ['activeOffer'],
  [AuthFeature.name]: [
    'isAuthenticated',
    'user',
    'currentPageUrl',
    'pageBeforeLogout'
  ],
  [CustomerFeature.name]: ['customer'],
  [RegionalConfigurationFeature.name]: [
    'regionalConfigurationOptions',
    'tipConfiguration',
    'featureFlags',
    'outageMessagePresentedDate'
  ],
  [LocationDetailsFeature.name]: ['locationDetails'],
  [LoyaltyPromptFeature.name]: ['isLoyaltyPromptDisplayed'],
  [RewardsPointsFeature.name]: ['availablePoints']
};

const saveableActions: Set<string> = new Set(
  [
    CartFeature.actions.setIsLoading,
    CartFeature.actions.setState,
    CartFeature.actions.resetToDefault,

    SelectedHandoffModeFeature.actions.setState,
    SelectedHandoffModeFeature.actions.resetToDefault,

    OrderFeature.actions.setIsLoading,
    OrderFeature.actions.setState,
    OrderFeature.actions.resetToDefault,

    StoreInfoFeature.actions.setIsLoading,
    StoreInfoFeature.actions.setState,
    StoreInfoFeature.actions.resetToDefault,

    ActiveOfferFeature.actions.setIsLoading,
    ActiveOfferFeature.actions.setState,
    ActiveOfferFeature.actions.resetToDefault,

    AuthFeature.actions.setCurrentpageurl,
    AuthFeature.actions.setPageBeforeLogout,
    AuthFeature.actions.setIsAuthenticated,
    AuthFeature.actions.setState,
    AuthFeature.actions.resetToDefault,

    CustomerFeature.actions.setIsLoading,
    CustomerFeature.actions.setState,
    CustomerFeature.actions.resetToDefault,

    RegionalConfigurationFeature.actions.setIsLoading,
    RegionalConfigurationFeature.actions.setState,
    RegionalConfigurationFeature.actions.setOutagemessagepresenteddate,
    RegionalConfigurationFeature.actions.resetToDefault,

    LocationDetailsFeature.actions.setIsLoading,
    LocationDetailsFeature.actions.setState,
    LocationDetailsFeature.actions.resetToDefault,

    LoyaltyPromptFeature.actions.setState,
    LoyaltyPromptFeature.actions.resetToDefault,

    RewardsPointsFeature.actions.setPoints,
    RewardsPointsFeature.actions.resetToDefault
  ]
    .filter((a) => a)
    .map((a) => a.type)
);

const saveSubsetBlacklistFilter: string[] = [
  CacheLoadedFeature.name,
  'appState',
  'storeInfo.storeInfo.fulfillmentTimes.isStoreAcceptingAsapOrders'
];

export const saveState = async (
  store: typeof localForage,
  nextState: FeaturesState
) => {
  const stateToSave = cloneDeep(nextState);
  const withBlackList = omit(stateToSave, saveSubsetBlacklistFilter);
  const withFilter = {
    ...withBlackList,
    ...Object.entries(saveSubsetFilter)
      .map(([key, value]) => ({
        key,
        value: pick(withBlackList[key as keyof FeaturesState], value)
      }))
      .reduce((acc, { key, value }) => ({ ...acc, [key]: value }), {})
  };
  try {
    await store.setItem(FEATURE_HYDRATION_KEY, withFilter);
  } catch (e) {
    console.error(e);
  }
};

const getCurrentState = (
  state?: FeaturesState
): DeepPartial<FeaturesState> => ({
  ...cloneDeep(state ?? {}),
  [CacheLoadedFeature.name]: { cacheLoaded: true }
});

const getSavedState = async (
  store: typeof localForage,
  key: string
): Promise<DeepPartial<FeaturesState>> => {
  try {
    return {
      ...((await store.getItem<DeepPartial<FeaturesState>>(key)) ?? {}),
      [CacheLoadedFeature.name]: { cacheLoaded: true }
    };
  } catch (e) {
    console.error(e);
    return {};
  }
};

export const setStateActionType = '[ROOT] [SET ALL]';

export const setStateAction = createAction(
  '[ROOT] [SET ALL]',
  props<{ payload: FeaturesState }>()
);

export const isSetStateAction = (
  a: Action
): a is ReturnType<typeof setStateAction> => a.type === setStateActionType;

export const getFeatureHydrationMiddleware = () =>
  providedOnceMiddleware(
    'featureHydrationApplied',
    (reducer: ActionReducer<FeaturesState>): ActionReducer<FeaturesState> =>
      (state, action) => {
        return reducer(
          isSetStateAction(action) ? action.payload : state,
          action
        );
      }
  );

@Injectable({ providedIn: 'root' })
export class HydrationEffects {
  constructor(
    private actions$: Actions,
    private store: Store,
    @Inject(STORAGE) private storage: typeof localForage
  ) { }

  readonly loadIntialState$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(ROOT_EFFECTS_INIT),
        concatLatestFrom(() => this.store as Observable<FeaturesState>),
        exhaustMap(([, state]) => {
          const currentState = getCurrentState(state);
          return from(getSavedState(this.storage, FEATURE_HYDRATION_KEY)).pipe(
            map((nextState) =>
              deepMergeAll(initialFeaturesState, currentState, nextState)
            )
          ) as Observable<FeaturesState>;
        }),
        map((nextState: FeaturesState) =>
          setStateAction({ payload: nextState })
        )
      );
    },
    { functional: true }
  );

  readonly saveNextState$ = createEffect(
    () => {
      return this.actions$.pipe(
        filter((action) => saveableActions.has(action.type)),
        concatLatestFrom(() =>
          (this.store as Observable<FeaturesState>).pipe(take(1))
        ),
        bufferWhileBusy(([, state]) => from(saveState(this.storage, state)))
      );
    },
    { functional: true, dispatch: false }
  );
}
export class FeatureHydrationMiddlewareProvider {
  static get(): Provider {
    return {
      provide: META_REDUCERS,
      multi: true,
      useFactory: getFeatureHydrationMiddleware
    };
  }
}

export const reducersLib: ActionReducerMap<FeaturesState> = {
  [CartFeature.name]: CartFeature.reducer,
  [CustomerFeature.name]: CustomerFeature.reducer,
  [SelectedHandoffModeFeature.name]: SelectedHandoffModeFeature.reducer,
  [OrderFeature.name]: OrderFeature.reducer,
  [AuthFeature.name]: AuthFeature.reducer,
  [CacheLoadedFeature.name]: CacheLoadedFeature.reducer,
  [ActiveOfferFeature.name]: ActiveOfferFeature.reducer,
  [StoreInfoFeature.name]: StoreInfoFeature.reducer,
  [RegionalConfigurationFeature.name]: RegionalConfigurationFeature.reducer,
  [LocationDetailsFeature.name]: LocationDetailsFeature.reducer,
  [LoyaltyPromptFeature.name]: LoyaltyPromptFeature.reducer,
  [RewardsPointsFeature.name]: RewardsPointsFeature.reducer,
};
