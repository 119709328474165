import { State, Store } from '@ngrx/store';
import { DOCUMENT } from '@angular/common';
import {
  AfterViewInit,
  Component,
  ElementRef,
  HostBinding,
  HostListener,
  Inject,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { Title } from '@angular/platform-browser';
import { NavigationEnd, Router } from '@angular/router';
import { NgbDropdownConfig, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Basket } from '@wingstop/models/basket.model';
import { Location } from '@wingstop/models/location/location.model';
import { Authentication } from '@wingstop/models/login/authentication.model';
import { AnalyticsService } from '@wingstop/services/analytics.service.service';
import { BasketService } from '@wingstop/services/basket.service';
import { UserIdentityService } from '@wingstop/services/user-identity-service';
import { IAppStore } from '@wingstop/store/app-store';
import { AppStateActions } from '@wingstop/store/app/app-state.actions';
import { AppStateSelectors } from '@wingstop/store/app/app-state.selectors';
import { SocialAuthService } from '@abacritt/angularx-social-login';
import { BehaviorSubject, filter, map, Observable, of, Subscription, switchMap } from 'rxjs';
import { environment } from '@wingstop/environments/environment';
import { InteractionStudioService } from '@wingstop/services/interaction-studio.service';
import { Cart } from 'src/app/lib/ngfe-app/src/lib/ecomm/types/cart.types';
import {LoyaltyBanner} from "../../../../lib/ngfe-app/src/lib/ecomm/types/customer.types";
import {CustomerFeature} from "../../../../lib/ngfe-app/src/lib/ecomm/store/features/customer";

declare const $: any;
declare const google: any;

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
  providers: [NgbDropdownConfig],
})
export class NavbarComponent implements OnDestroy, OnInit, AfterViewInit {
  @ViewChild('skipLink') skipLink: ElementRef;
  @ViewChild('logoutBtn') logoutBtn: ElementRef;
  @ViewChild('closeMobileMenuBtn') closeMobileMenuBtn: ElementRef;

  public basket: Basket = null;
  public selectedLocation: Location = null;
  public authentication: Authentication = null;
  public mobileMenuOpen = false;
  public numItems = 0;
  public userIsSelectingLocation = false;
  public forceShowOrderActonButtons = false;
  public deliveryMode: any;
  // derivitive data
  public handoffModeLabel = 'Ordering from';
  public currentUrl: String = null;
  public isOrderFlow: boolean = false;
  public kiosk = false;
  public careersConditionalClass: string;
  public offerDetails: any;
  public digitalMenu = false;
  public shouldShow$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public showAppBanner$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public isEnglish = true;
  public isSpanishTranslationEnabled = true;
  public environment = environment;
  public menuPath: string;
  public isAuthenticated: boolean;
  public firstName: string;
  public lastName: string;
  public wingCalculatorPath = 'order/wing-calculator';
  public flavorsPath = 'flavors';
  public rewardsPath = 'account/rewards';
  public ngfeOrderPath = '/order'
  @HostBinding('attr.aria-label') pageTitle = '';
  private subscriptions: Subscription[] = [];
  private orderFlowPaths = ['location', 'order'];
  public appCart: Cart
  public isLoading = false;
  public loyalty: LoyaltyBanner;
  private subscription = new Subscription();
  isOffline$: Observable<boolean>;


  constructor(
    private router: Router,
    private interactionStudioService: InteractionStudioService,
    private appStateSelectors: AppStateSelectors,
    private appStore: State<IAppStore>,
    private appStateActions: AppStateActions,
    private authService: SocialAuthService,
    private basketService: BasketService,
    private analyticsService: AnalyticsService,
    private modalService: NgbModal,
    private userIdentityService: UserIdentityService,
    private title: Title,
    private store: Store,
    @Inject(DOCUMENT) private document: Document) {
    const params = new URL(window.location.href).searchParams;
    this.kiosk = !!params.get('kiosk');
    this.subscriptions.push(
      this.appStateSelectors.isDigitalMenu.subscribe(
        (isDigitalMenu: boolean) => {
          this.digitalMenu = isDigitalMenu;
          this.shouldShow$.next(!this.kiosk && !this.digitalMenu);
        }
      )
    );
    this.subscriptions.push(
      this.appStateSelectors.showAppBanner.subscribe(
        (showAppBanner: boolean) => {
          this.showAppBanner$.next(showAppBanner);
        }
      )
    );
  }

  @HostListener('window:scroll', ['$event'])
  onWindowScroll($event: Event) {
    if (window.scrollY >= 575 && window.location.href.includes('careers')) {
      this.careersConditionalClass = 'hideNav';
    } else {
      this.careersConditionalClass = '';
    }
  }

  async ngOnInit(): Promise<void> {
    this.subscribeToRegionalConfigState()
    this.appStore.subscribe((value) => {
      const { storeInfo } = value?.storeInfo || {};
      const { cart } = value?.cart || {};
      this.appCart = cart;
      const { isAuthenticated } = value?.auth || {};
      this.isAuthenticated = isAuthenticated;
      const { customer } = value?.customer || {};
      this.firstName = customer?.firstName ?? "";
      this.lastName = customer?.lastName ?? "";
      if (
        value?.regionalConfiguration?.regionalConfigurationOptions &&
        value?.regionalConfiguration?.regionalConfigurationOptions[
        'feature_enable_spanish_translation'
        ]
      ) {
        this.isSpanishTranslationEnabled =
          value?.regionalConfiguration?.regionalConfigurationOptions[
            'feature_enable_spanish_translation'
          ].value === 'false' ? false : true;
      }

      if (!storeInfo) {
        this.menuPath = 'menu';
      } else {
        const slug = storeInfo?.storeDetails?.slug;
        this.menuPath = slug ? `/location/${slug}/menu` : 'order';
      }
    });

    this.isEnglish = !window.location.hostname.includes('es.');
    this.router.events.subscribe((r) => {
      this.closeMobileMenu();
      this.userIsSelectingLocation =
        this.router.url.toString() === '/order' ||
        this.router.url.toString() === '/order/action/carryout' ||
        this.router.url.toString() === '/order/action/delivery' ||
        this.router.url.toString() === '/order/handoffmode/delivery';
      this.forceShowOrderActonButtons =
        this.router.url.toString() === '/order/wing-calculator' ||
        this.router.url.toString() === '/order/recent';
    });
    this.router.events
      .pipe(filter((e) => e instanceof NavigationEnd))
      .subscribe((navEnd: NavigationEnd) => {
        this.currentUrl = navEnd.urlAfterRedirects.substr(
          navEnd.urlAfterRedirects.lastIndexOf('/')
        );
        const rootPath = navEnd.urlAfterRedirects.substr(1).split('/')[0];
        this.isOrderFlow = this.orderFlowPaths.indexOf(rootPath) !== -1;

        this.pageTitle = this.title.getTitle();
      });

    // subscribe to authentication
    let authSub = this.appStateSelectors.authentication.subscribe((value) => {
      this.authentication = value;
    });

    // subscribe to a location change
    let locationSub = this.appStateSelectors.selectedLocation.subscribe(
      (value) => {
        this.selectedLocation = value;
      }
    );

    // subscribe to a basket change
    let basketSub = this.appStateSelectors.basket.subscribe((value) => {
      this.basket = value;
      if (this.basket) {
        if (this.basket.products) {
          this.numItems = this.basket.products.reduce(
            (a, b) => a + b.quantity,
            0
          );
        } else {
          this.numItems = 0;
        }
        if (this.basket.deliverymode === Basket.MODE_PICKUP) {
          this.handoffModeLabel = 'Carryout from';
        }
        if (this.basket.deliverymode === Basket.MODE_DISPATCH) {
          this.handoffModeLabel = 'Delivery from';
        }
        if (this.basket.deliverymode === Basket.MODE_CURBSIDE) {
          this.handoffModeLabel = 'Curbside from';
        }
        if (this.basket.deliverymode === Basket.MODE_DINEIN) {
          this.handoffModeLabel = 'Dine-In from';
        }
      } else {
        this.numItems = 0;
      }
    });

    let deliveryModeSub = this.basketService.deliveryMode.subscribe((mode) => {
      this.deliveryMode = mode;
    });

    this.subscriptions.push(authSub);
    this.subscriptions.push(locationSub);
    this.subscriptions.push(basketSub);
    this.subscriptions.push(deliveryModeSub);
    this.subscribeToCustomerState();
  }

  private subscribeToRegionalConfigState(): void {
    this.isOffline$ = this.router.events.pipe(
      filter(event => event instanceof NavigationEnd),
      map(() => this.router.url),
      map(() => this.router.url === '/offline'),
      switchMap(isOfflineRoute => {
        return isOfflineRoute
          ? of(true)
          : of(false)
      })
    );
  }

  private subscribeToCustomerState(): void {
    const customerState$ = this.store
      .select(CustomerFeature.selectCustomerState)
      .pipe(filter(Boolean));

    this.subscription.add(
      customerState$.subscribe((state) => {
        this.loyalty = state.customer?.loyalty
      })
    );
  }

  ngAfterViewInit() {
    const mainContent = document.getElementById('main-content');
    this.skipLink.nativeElement.addEventListener(
      'keydown',
      (e: KeyboardEvent) => {
        if (e.key === 'Enter') {
          e.preventDefault();
          if (mainContent) {
            mainContent.focus();
          }
        }
      }
    );
  }

  toggleMobileMenu() {
    this.mobileMenuOpen = !this.mobileMenuOpen;

    this.logoutBtn?.nativeElement.addEventListener(
      'keydown',
      (e: KeyboardEvent) => {
        if (
          this.logoutBtn.nativeElement === this.document.activeElement &&
          e.key === 'tab'
        ) {
          e.preventDefault();
          this.closeMobileMenuBtn.nativeElement.focus();
        }
      }
    );

    if (this.mobileMenuOpen) {
      this.document.getElementById('body').setAttribute('aria-hidden', 'true');
      this.document.body.classList.add('mobile-menu-open');
      this.document
        .querySelector('.app-footer')
        .setAttribute('aria-hidden', 'true');
    } else {
      this.document.getElementById('body').removeAttribute('aria-hidden');
      this.document.body.classList.remove('mobile-menu-open');
      this.document.querySelector('.app-footer').removeAttribute('aria-hidden');
    }

    this.analyticsService.logGaEvent({
      event: 'hamburger_menu_click',
    });
  }

  closeMobileMenu() {
    this.mobileMenuOpen = false;
    this.document.getElementById('body').removeAttribute('aria-hidden');
    this.document.body.classList.remove('mobile-menu-open');
    this.document.querySelector('.app-footer').removeAttribute('aria-hidden');
  }

  ngOnDestroy() {
    this.subscriptions.map((s) => s.unsubscribe());
  }

  skipToMain() {
    const mainContent = document.getElementById('main-content');
    // $([this.document.documentElement, this.document.body]).animate({
    //   scrollTop: mainContent.offset().top
    // }, 1000);
    mainContent.focus();
  }

  displayActiveOfferIcon(): boolean {
    return !!(
      this.appStore.getValue().appState.cmsOffer &&
      this.appStore.getValue().appState.cmsOfferRedeemCode
    );
  }

  handleOnLogout(event: any) {
    if (event) {
      event.preventDefault();
    }

    this.appStateActions.openAlertModalWith(
      'Confirm',
      'Are you sure you want to log out?',
      'Yes',
      null,
      'No',
      true,
      false,
      (whatHappened: string) => {
        if (whatHappened === 'ok') {
          this.interactionStudioService.interactionStudioWsLogout();
          this.appStateActions.userLoggedOut().then((r) => {
            this.analyticsService.logGaEvent({
              event: 'logout',
            });
            this.analyticsService.logGaEvent({
              event: 'account_click_option',
              account_option_value: 'Logout',
            });
            this.analyticsService.logGaEvent({
              user_id: null,
            });
            this.googleSignOut();
            this.authService.signOut().catch((e: any) => {
              console.log(e);
            });
            this.userIdentityService.setSignupViaSecretMenu(null);

            this.router.navigate([], {
              queryParamsHandling: 'preserve',
            });
          });
        }
      }
    );
  }

  googleSignOut(): void {
    google.accounts.id.disableAutoSelect();
  }

  dispatchReturnToBasketEvent() {
    this.analyticsService.logGaEvent({
      event: 'return_to_cart',
    });
  }

  onCtaClick(event: Event, route: string) {
    event.preventDefault();

    const routeLookUp = {
      'account-popup': {
        event: 'account_click',
        screen_name: this.currentUrl,
      },
      '/account/login': {
        event: 'account_click_option',
        account_option_value: 'Log In',
      },
      '/account/signup': {
        event: 'account_click_option',
        account_option_value: 'Sign up',
      },
      '/account/settings': {
        event: 'account_click_option',
        account_option_value: 'My Account',
      },
      '/order/recent': {
        event: 'account_click_option',
        account_option_value: 'My Orders',
      },
      '/order/my-bag': {
        event: 'bag_click',
        screen_name: this.currentUrl,
      },
      '/order': {
        event: 'header_click',
        header_click_value: 'Locations',
        screen_name: this.currentUrl,
      },
      '/menu': {
        event: 'header_click',
        header_click_value: 'Menu',
        screen_name: this.currentUrl,
      },
      '/order/action/carryout': {
        event: 'order_click',
        handoff: 'Carryout',
        screen_name: this.currentUrl,
      },
      '/order/action/delivery': {
        event: 'order_click',
        handoff: 'Delivery',
        screen_name: this.currentUrl,
      },
    };

    if (route === this.menuPath) {
      this.analyticsService.logGaEvent(routeLookUp['/menu']);
    }

    if (routeLookUp[route]) {
      this.analyticsService.logGaEvent(routeLookUp[route]);
    }

    if (route === 'account-popup') {
      return;
    }

    this.router.navigate([route], {
      queryParamsHandling: 'preserve',
    });
  }

  navigateToCarryoutOrder() {
    this.router.navigate(['/order']);
  }

  onLoginClick($event: boolean) {
    if ($event) {
      this.isLoading = true
    } else {
      this.isLoading = false
    }
  }
}
